function loadPlayground() {
  import(/* webpackPrefetch: true */ "./graphiqlPlayground").then((module) => {
    const name = "airsequel-graphql-playground"

    if (customElements.get(name) === undefined) {
      customElements.define(name, module.AirsequelPlayground)
    }
  })
}

// If we are already on the right route, load this right away.
//
// This has the advantage of requesting both this and the elm code in parallel.
if (/^\/dbs\/.*\/graphiql.*$/.test(window.location.pathname)) {
  loadPlayground()
}

// Otherwise, the elm code will call loadPlayground at some point later down the line.
import("./elm").then((m) => m.main(loadPlayground))
